<template>
	<modal
		name="select-cards"
		height="auto"
		adaptive
		:width="330"
		:minWidth="500"
		:scrollable="true"
		:shiftY="0.4"
		@before-open="beforeOpen"
	>
		<div
			class="select-cards-wrapper"
			:style="
			getTheme === 'dark-theme' && {
				backgroundColor: '#151515',
				border: '1px solid #4B4B54',
			}
			"
		>
			<div class="select-cards-title">
				<div></div>
				<span class="title-text" :style="getTheme === 'dark-theme' && { color: '#CDCDD6' }">
					Сохраненные карты
				</span>
				<div class="app-modal__close">
					<AppBtn
						@click.native="hideModal"
						icon
						text
						alt="Закрыть модальное окно"
						title="Закрыть модальное окно"
					>
						<AppIcon name="times" size="1.25rem" />
					</AppBtn>
				</div>
			</div>
			<div class="cards-list" v-if="getProfileCards.length !== 0">

				<AppCard
					style="margin-bottom: 10px"
					v-for="item in getProfileCards"
					:key="item.id"
				>
					<template #body>
						<div
							:style="getTheme === 'dark-theme' && { color: '#CDCDD6' }"
							class="description-text"
							@click="selectCard(item)"
						>
							<div class="icon-wrapper">
								<img
									v-if="item.logo"
									width="50"
									style="border-radius: 20%"
									:src="`http://cdn.jfservice.ru/brands/${item.logo}`"
								/>
								<img v-else src="/img/payment-item.svg" />
							</div>
							<div
								>{{ item.pan
								}}<span style="margin-left: 10px">{{
									item.expDate && $addString(item.expDate, 2, "/")
								}}</span></div
							>
							<div>
								<AppIcon
									v-if="cardId.id === item.id"
									name="check"
									size="1.25rem"
									styleCss="color: #1DC571"
								/>
							</div>

						</div>
					</template>
				</AppCard>
			</div>
			<div class="cards-add">
				<AppCard>
					<template #body>
						<div :style="getTheme === 'dark-theme' && { color: '#CDCDD6' }" class="new-card"
							@click="selectNewCard()">
							Новая карта
						</div>
					</template>
				</AppCard>
			</div>
		</div>
	</modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			cardId: {
				pan: "",
				id: "",
			},
			cardList: false,
			paramsModal: {
				model: {},
			},
		};
	},
	props: {},
	mounted() { },
	computed: {
		...mapGetters(["getProfileCards", "getSelectedCard", "getTheme"]),
	},
	methods: {
		spliceCardNumber(string) {
			let number1 = string.split('').splice(0, 4).join('')
			let number2 = string.split('').splice(-4, 4).join('')
			return number1 + ' **** **** ' + number2
		},
		selectCard(data) {
			const selectedCardData = {
				pan: data.pan,
				id: data.id,
			};
			localStorage.setItem("card-id", JSON.stringify(selectedCardData));
			this.cardId = selectedCardData;
			this.$store.commit("setSelectedCard", selectedCardData);
			this.$modal.hide("select-cards");
		},
		selectNewCard() {
			this.$store.commit("setSelectedNewCard");
			localStorage.setItem("card-id", JSON.stringify({ pan: "", id: "" }));
			this.$modal.hide("select-cards");
		},

		hideModal() {
			this.$emit("hideModal");
			this.$modal.hide("select-cards");
		},
		async beforeOpen(event) {
			this.loadCards();
			const id = JSON.parse(localStorage.getItem("card-id"));
			this.cardId = id ? id : { pan: "", id: "" };
		},
		async beforeClose() {},
		async loadCards() {
			try {
				await this.$store.dispatch("getCards");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.$errorsMod(e);
				}
			}
		},
	},
};
</script>

<style scoped lang="sass">
.my-card
	height: 100px
	width: 200px
	border-radius: 9px
	padding: 10px
	box-shadow: 7px 3px 3px 0 RGBA(10, 42, 97, .2)
	background: #0a2a61
	opacity: .9
	cursor: pointer
	position: relative
	&:hover
		opacity: 1
	&__wrapper
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 20px
		margin: 10px 0
	&__check
		position: absolute
		height: 50px
		width: 50px
		border-radius: 50px
		background: #fff
		box-shadow: inset 7px 3px 3px 0 RGBA(10, 42, 97, .2)
		&>div:first-child
			height: 30px
			width: 20px
			border-bottom: 6px solid green
			border-right: 6px solid green
			transform: translate(15px, 5px)rotate(45deg)
			box-shadow: 7px 3px 3px 0 RGBA(10, 42, 97, .2)
			transition: all 0.3s
			opacity: 0
			&.active
				opacity: 1
	&__icon
		height: 50px
		width: 70px
		margin: 0 0 0 auto
		overflow: hidden
		display: flex
		justify-content: center
		align-items: center
		&>img
			transform: scale(160%)
	&__number
		color: white
		margin-top: 10px
		font-size: 16px
		font-family: monospace
		letter-spacing: -0.5px


.select-cards-wrapper
	padding: 15px
	background: #FFF
	border-radius: 20px

.select-cards-title
	display: flex
	justify-content: space-between

.title-text
	color: #0a2a61
	font-weight: 500
	font-size: 24px

.description-text
	cursor: pointer
	display: grid
	gap: 10%
	grid-template-columns: 50px 1fr 50px
	color: #171715
	margin: -10px -10px
	&:hover
		opacity: 0.5
	>div
		display: flex
		justify-content: center
		align-items: center
		&:nth-child(2)
			justify-content: start
.cards-list
	margin-top: 20px
	margin-bottom: 20px

.cards-add
	margin-top: 20px
	margin-bottom: 10px

.new-card
	cursor: pointer
	text-align: center
	color: #171715
	&:hover
		opacity: 0.5

.icon-wrapper
	width: 50px
	height: 50px
</style>
