<template>
	<modal
		name="payment-confirmation-technical-service"
		height="auto"
		:width="500"
		:minWidth="500"
		adaptive
		:scrollable="true"
	>
		<div
			class="conf"
			:style="
				getTheme === 'dark-theme' && {
					backgroundColor: 'rgb(21, 21, 21)',
					border: '1px solid rgb(75, 75, 84)'
				}
			"
		>
			<div class="conf__top-container">
				<div />
				<h3
					class="conf__title"
					:style="getTheme === 'dark-theme' && { color: '#CDCDD6' }"
				>
					Подтвердите оплату
				</h3>
				<div class="conf__close">
					<AppBtn
						@click.native="hideModal"
						icon
						text
						alt="Закрыть модальное окно"
						title="Закрыть модальное окно"
					>
						<AppIcon name="times" size="1.25rem" />
					</AppBtn>
				</div>
			</div>

			<p
				class="conf__text"
				:style="getTheme === 'dark-theme' && { color: '#CDCDD6' }"
			>
				Подтвердите оплату на сумму {{ gazPayments ? gazPayments.amount : 0 }} ₽ с карты
				{{
					"**" +
						getSelectedCard.pan
							.split("")
							.splice(-4, 4)
							.join("")
				}}
			</p>

			<div class="conf__buttons">
				<AppBtn
					large
					outlined
					@click.native="hideModal"
					customClass="conf__button"
				>
					Отмена
				</AppBtn>
				<AppBtn
					large
					color="primary"
					@click.native="submitModal"
					customClass="conf__button"
					:loading="loading"
				>
					Оплатить
				</AppBtn>
			</div>
		</div>
	</modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
      loading: false
     };
	},
	props: ["gazPayments"],
	computed: {
		...mapGetters(["getTheme", "getSelectedCard", "getPayOrderData"])
	},
	methods: {
		hideModal() {
			this.$emit("hideModal");
			this.$modal.hide("payment-confirmation-technical-service");
		},

		async submitModal() {
			this.loading = true;
			const data = {
				payload: {
					data: [],
					gazPayments: [this.gazPayments],
					cardId: this.getSelectedCard.id
				}
			};

      console.log('data', data)

			await this.$store.dispatch("payOrder", data);

			try {
				if (
					this.getPayOrderData.data.successed &&
					this.getSelectedCard.selected
				) {
					this.$router.push({
						path: `/bank/approved?Success=true&orderId=${this.getPayOrderData.data.orderId}&noSavecard=true`
					});
				} else if (!this.getPayOrderData.data.successed) {
					this.$router.push({
						path: `/bank/rejected?Success=false&message=${this.getPayOrderData.data.message}&orderId=${this.getPayOrderData.data.orderId}`
					});
				}
			} catch (e) {
				this.$errorsMod(e.response);
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style scoped>
.conf {
	display: flex;
	flex-direction: column;
	padding: 15px;
	border-radius: 20px;
}

.conf__top-container {
	display: flex;
	justify-content: space-between;
}

.conf__title {
	font-size: 20px;
	line-height: 24px;
}

.conf__text {
	font-size: 16px;
	line-height: 19px;
	margin: 24px auto 40px auto;
	color: #777776;
}

.conf__buttons {
	display: flex;
	gap: 15px;
}

.conf__button {
	width: 100%;
}
</style>
