<template>
	<AppGlobalLayout
		:loading="getPreparePaymentsList.loading"
		:error="error"
		@reload="loadInfo"
	>
		<div slot="body">
			<SelectCardsModal />
			<PaymentConfirmation
				:sumWithFee="sumWithFee"
			/>
			<div class="payment-all__list">
				<AppTable
					:header="headerTable"
					:data="$v.payments.$each.$iter"
					messageNoData="Нет ЛС / Договоров для оплаты"
					small
					class="table-payments"
				>
					<template #thead="{ item }">
						<span>{{ item.name }}</span>
					</template>
					<template v-slot:tbody="{ item, index }">
						<td
							class="payment-list__address"
							:style="`opacity: ${item.$model.canPay ? '1' : '0.5'}`"
						>
							<span>{{ item.$model.address }}</span>
						</td>
						<td :style="`opacity: ${item.$model.canPay ? '1' : '0.5'}`">
							<span class="app-table__title-mob"> ЛС / Договор: </span>
							<span style="margin-right: 1em;">{{
								item.$model.accountName
							}}</span>
						</td>
						<td :style="`opacity: ${item.$model.canPay ? '1' : '0.5'}`">
							<div
								v-if="apartmentHasInsurance(item.$model.apartmentId)"
								class="insurance"
							>
								+&nbsp;страховка
							</div>
						</td>

						<td :style="`opacity: ${item.$model.canPay ? '1' : '0.5'}`">
							<span class="app-table__title-mob"> Поставщик: </span>
							<span>{{ item.$model.organizationName }}</span>
						</td>
						<td :style="`opacity: ${item.$model.canPay ? '1' : '0.5'}`">
							<span style="min-width: 110px; display: block">
								<AppInputPay
									type="text"
									:disabled="!item.$model.canPay"
									v-model="item.sum.$model"
									:error="item.$error"
									:required="!item.sum.required"
									:errorMin="item.$error"
									:errorMax="!item.sum.maxValue"
									:index="Number(index)"
								/>
							</span>
						</td>
						<td
							:style="`opacity: ${item.$model.canPay ? '1' : '0.5'}`"
							class="pb-2--xs"
						>
							<span class="app-table__title-mob"> Тариф сервиса: </span>
							<span>
								{{
									(item.$model.feeType == 0 && item.$model.sum > 0
										? (+item.$model.sum *
												(+item.$model.feePercent - +item.$model.discount)) /
										  100
										: 0
									).toFixed(2)
								}}
							</span>
						</td>
						<td
							class="payment-list__delete"
							:style="`opacity: ${item.$model.canPay ? '1' : '0.5'}`"
						>
							<span>
								<AppBtn
									@click.native="removePay(index)"
									icon
									text
									alt="Уведомления"
									title="Уведомления"
								>
									<AppIcon name="times" size="1rem" />
								</AppBtn>
							</span>
						</td>
					</template>
				</AppTable>
			</div>

			<div class="payment-all__footer">
				<AppCard class="payment-all__card">
					<template #body>
						<div class="payment-all__footer-row">
							<div>
								<div class="payment-all__card-img">
									<img
										v-if="getTheme !== 'dark-theme'"
										src="/img/pay-cards.png"
										alt=""
									/>
									<img v-else src="/img/dark-theme/visa-dark.png" alt="" />
								</div>
								<div
									class="payment-all__card-info"
									@click="showSelectCardsModal"
								>
									<div class="payment-all__card-info-icon">
										<img src="img/payment-item-op.svg" alt="" />
									</div>
									<div class="payment-all__card-info-item">
										<div
											class="select"
											v-if="
												getSelectedCard.selected &&
													getProfileCards.find(
														it => it.id === getSelectedCard.id
													)
											"
										>
											{{
												"**" +
													getSelectedCard.pan
														.split("")
														.splice(-4, 4)
														.join("")
											}}
										</div>
										<div class="empty" v-else>Мои карты</div>
									</div>
									<AppIcon
										name="angle-down"
										size="20px"
										styleCss="color:white"
									/>
								</div>
							</div>
							<div class="payment-all__payment-info-wrapper">
								<div class="payment-all__payment-info">
									<div
										v-if="!canPay"
										class="valid-canPay"
										style="max-width: 300px"
									>
										<i class="fas fa-exclamation-circle mr-1"></i>
										{{
											canPay
												? ""
												: "К сожалению, по выбранным лицевым счетам оплата невозможна."
										}}
									</div>
									<div class="payment-all__insurance">
										<div class="payment-all__insurance-text-icon">
											<img
												src="../../img/insurance/insurance-icon.svg"
												alt=""
											/>
											<span class="payment-all__insurance-text"
												>Страхование</span
											>
											<!-- <span
												v-if="includeInsurance"
												class="payment-all__insurance-text"
												style="font-weight: 600; color: var(--color-secondary)"
											>
												Страховка:
											</span> -->
											<span
												v-if="includeInsurance"
												class="payment-all__insurance-number"
											>
												{{ totalInsurance }} ₽
											</span>
										</div>

										<!-- <AppToggleBtn v-model="includeInsurance" @change="buyInsurance" /> -->
										<AppBtn
											v-if="includeInsurance"
											color="danger"
											@click.native="buyInsurance(false)"
										>
											Удалить
										</AppBtn>
										<AppBtn
											v-else-if="!includeInsurance"
											:disabled="!canPay"
											color="success"
											@click.native="buyInsurance(true)"
										>
											Добавить
										</AppBtn>
									</div>

									<div class="payment-all__total">
										Итого к оплате:
										<span class="payment-all__total-number">
											{{
												includeInsurance
													? $digitNumber(
															(sumTotal + summaryFee + totalInsurance).toFixed(
																2
															)
													  )
													: $digitNumber((sumTotal + summaryFee).toFixed(2))
											}}
											₽
										</span>
									</div>
									<!-- Выводится комиссия или минимальная комиссия -->
									<div
										class="green-color"
										:style="hasDiscount ? 'text-decoration: line-through;' : ''"
									>
										{{ minFeeAttention }}
									</div>
									<div class="payment-all__commission">
										<div
											v-if="sumTotal + summaryFee > 300000"
											class="payment-all__summ-error"
										>
											Максимальная сумма платежа 300 000 ₽
										</div>

										<div
											class="payment-all__commission--sale"
											v-if="hasDiscount"
										>
											<AppIcon name="tag" size="14px" />
											Тариф сервиса со скидкой: &#160;<span>
												{{ " " + $digitNumber(sumfeePercent.toFixed(2)) }} ₽
											</span>
										</div>
									</div>
								</div>

								<div class="payment-all__pay-button">
									<AppBtn
										large
										@click.native="paymentSubmit"
										:disabled="
											!valid ||
												!canPay ||
												$v.sumTotal.$invalid ||
												$v.payments.$error
										"
									>
										Оплатить
									</AppBtn>
								</div>
							</div>
						</div>
						<div class="payment-all__description">
							<a
								target="_blank"
								href="https://квартплата.онлайн/files/oferta.pdf"
								>Нажимая кнопку «Оплатить» я выражаю своё согласие на
								присоединение к <span>договору оферты</span> с условиями
								оказание услуг</a
							>
						</div>
					</template>
				</AppCard>
			</div>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import SelectCardsModal from "./select-cards-modal.vue";
import PaymentConfirmation from "./payment-confirmation.vue";

const minValueItem = value => value == 0 || value >= 1;
const minValue = value => value >= 1;
const maxValue = value => value <= 300000;

function computeFee(arr) {
	function getFee(item) {
		return +(
			((+item.sum >= 0 ? +item.sum : 0) * (+item.feePercent - +item.discount)) /
			100
		).toFixed(2);
	}
	const array = arr.filter(item => item.canPay);
	let totalFee = array.reduce((total, item) => {
		let fee = getFee(item);
		return fee + total;
	}, 0);
	let minFee = array.reduce((min, item) => {
		return +item.minAmountFee > min ? +item.minAmountFee : min;
	}, 0);
	let companyFee = array
		.filter(item => +item.feeType !== 0)
		.reduce((sum, item) => {
			let fee = getFee(item);
			return fee + sum;
		}, 0);
	let userFee = +(totalFee - companyFee).toFixed(2);

	const commonFee = array
		.filter(item => {
			return item.feeType === 0;
		})
		.reduce((sum, item) => {
			const itemWithFee =
				((+item.sum >= 0 ? +item.sum : 0) * +item.feePercent) / 100;
			return +sum + itemWithFee;
		}, 0);

	return { userFee, minFee, companyFee, commonFee };
}

export default {
	data() {
		return {
			includeInsurance: false,
			valid: true,
			error: false,
			payments: [],
			headerTable: [
				{
					name: "Потребитель",
					width: "30%"
				},
				{
					name: "ЛС / Договор",
					width: "12%"
				},
				{
					name: "",
					width: "10%"
				},
				{
					name: "Поставщик",
					width: "16%"
				},
				{
					name: "Сумма",
					width: "12%"
				},
				{
					name: "Тариф сервиса",
					width: "10%"
				},
				{
					name: "",
					width: "2%"
				}
			]
		};
	},
	props: {
		allPayment: {
			type: Boolean,
			default: false
		},
		accountId: {
			type: Number,
			default: null
		},
		apartmentId: {
			type: Number,
			default: null
		},
		sum: {
			type: Number,
			default: 0
		},
		paymentMoth: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		// сохранение списка платежей перед тем как перейти на страницу страховок
		if (
			this.$route.query.from === "insurance" &&
			this.getPaymentsFromPaymentPage.length
		) {
			this.payments = this.getPaymentsFromPaymentPage;
		} else if (
			this.$route.query.from === "insurance" &&
			this.getPaymentsFromPaymentPage.length
		) {
			this.$router.push({ name: "paymentPage" });
			this.loadInfo();
			this.$store.commit("setInsurancePrepare", []);
		} else {
			this.loadInfo();
			this.$store.commit("setInsurancePrepare", []);
		}
		this.loadCards();
		if (this.getInsurancePrepare.length > 0) {
			this.includeInsurance = true;
		}
	},
	beforeDestroy() {
		if (this.getInsurancePrepare.length > 0) {
			this.$store.commit("setInsurancePrepare", []);
		}
	},
	computed: {
		...mapGetters([
			"getPreparePaymentsList",
			"getPayOrderData",
			"getTheme",
			"getSelectedCard",
			"getProfileCards",

			"getInsurancePrepare",
			"getInsurancePayments",
			"getPaymentsFromPaymentPage"
		]),
		sumTotal() {
			return this.payments.reduce(function(total, value) {
				return value.canPay ? total + Number(value.sum) : total + 0;
			}, 0);
		},
		sumWithFee() {
			if (this.includeInsurance) {
				return (this.sumTotal + this.summaryFee + this.totalInsurance).toFixed(
					2
				);
			}
			return (this.sumTotal + this.summaryFee).toFixed(2);
		},
		sumfeePercent() {
			return this.payments.reduce(function(total, value) {
				const fee =
					value.feePercent - +value.discount > 0
						? value.feePercent - +value.discount
						: 0;

				return value.canPay
					? total + (Number(value.sum) * Number(fee)) / 100
					: total + 0;
			}, 0);
		},
		canPay() {
			return this.payments.some(item => {
				return item.canPay;
			});
		},
		hasDiscount() {
			return this.payments.some(
				item => +item.feePercent - +item.discount > 0 && +item.discount > 0
			);
		},
		summaryFee() {
			//   рассчет комисси для пользователя
			const { userFee, minFee, companyFee } = computeFee(this.payments);
			if (companyFee > minFee) {
				return userFee;
			}
			if (companyFee <= minFee) {
				if (userFee > minFee) {
					return userFee;
				} else if (userFee === 0) {
					return 0;
				} else if (userFee <= minFee) {
					return minFee;
				}
			}
		},
		minFeeAttention() {
			//   сообщение под Итого
			const { userFee, minFee, companyFee, commonFee } = computeFee(
				this.payments
			);

			if (companyFee > minFee) {
				if (userFee === 0) {
					return "";
				}
				return `* Тариф сервиса ${userFee.toFixed(2)}  ₽`;
			}
			if (companyFee <= minFee) {
				if (userFee > minFee && userFee > 0) {
					return `* Тариф сервиса ${commonFee.toFixed(2)}  ₽`;
				} else if (userFee === 0) {
					return "";
				} else if (userFee <= minFee) {
					return `* Применён минимальный тариф сервиса ${minFee.toFixed(2)}  ₽`;
				}
			}
		},
		totalInsurance() {
			return this.getInsurancePrepare.reduce(
				(acc, item) => item.monthCount * item.program.price + acc,
				0
			);
		}
	},
	methods: {
		apartmentHasInsurance(apartmentId) {
			return this.getInsurancePrepare.some(
				item => item.apartmentId === apartmentId
			);
		},
		async loadCards() {
			try {
				await this.$store.dispatch("getCards");
				if (
					!this.getProfileCards.find(it => it.id === this.getSelectedCard.id)
				) {
					this.$store.commit("setSelectedNewCard");
					localStorage.setItem("card-id", JSON.stringify({ pan: "", id: "" }));
				}
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.$errorsMod(e);
				}
			}
		},
		showSelectCardsModal() {
			this.$modal.show("select-cards");
		},
		showPaymentConfirmationModal(data) {
			this.$modal.show("payment-confirmation", data);
		},

		async loadInfo(event) {
			//allPayment оплата по 1 ЛС или по всем
			//paymentMoth оплата за конкретный месяц

			const cardId = JSON.parse(localStorage.getItem("card-id"));
			if (!cardId) {
				this.$store.commit("setSelectedCard", "");
			} else this.$store.commit("setSelectedCard", cardId);

			try {
				if (this.apartmentId) {
					this.payments = await this.$store.dispatch(
						"preparePaymentsByApartment",
						{
							apartmentId: this.apartmentId
						}
					);
				} else if (this.accountId) {
					this.payments = await this.$store.dispatch(
						"preparePaymentsByAccount",
						{
							accountId: this.accountId
						}
					);
				} else {
					this.payments = await this.$store.dispatch("preparePayments");
				}

				this.paymentMoth ? (this.payments[0].sum = this.sum) : false;

				for (let i = 0; i < this.payments.length; i++) {
					//проверяем есть ли сумма меньше 0
					//проверка если есть суммы < 0 тогда подставляем 0
					if (this.payments[i].sum < 0) {
						this.payments[i].sum = 0;
					}
				}
			} catch (e) {
				this.error = this.$errorsMod(e.response);
			}
		},
		async paymentSubmit() {
			this.returnValid();

			await this.$store.dispatch("sendInsuranceCreate");

			if (this.valid) {
				let filter = this.payments.filter(item => {
					return item.canPay && item.sum >= 1;
				});
				let payment = filter.map((item, index) => {
					return {
						accountId: item.accountId,
						amount: item.sum,
						feePercent: item.feePercent,
						fee: ((item.sum * item.feePercent) / 100).toFixed(2)
					};
				});
				const data = {
					payload: {
						data: payment,
						insurancePayments: this.getInsurancePayments,
						cardId: this.getSelectedCard.id
					}
				};

				try {
					if (this.getSelectedCard.id !== "") {
						this.showPaymentConfirmationModal(data);
					} else {
						await this.$store.dispatch("payOrderSelected", data);

						if (
							this.getPayOrderData.data.successed &&
							!this.getSelectedCard.selected
						) {
							// Если оплачивает новой картой
							window.location.replace(this.getPayOrderData.data.url);
						} else if (!this.getPayOrderData.data.successed) {
							this.$router.push({
								path: `/bank/rejected?Success=false&message=${this.getPayOrderData.data.message}&orderId=${this.getPayOrderData.data.orderId}`
							});
						}
					}
				} catch (e) {
					this.$errorsMod(e.response);
				}
			}
		},
		removePay(index) {
			const removedAccount = this.payments[index];
			this.payments.splice(index, 1);
			this.returnValid();
			//если удаляются все счета одной квартиры, то и страховка этой квартиры удаляется
			if (
				!this.payments.some(
					item => item.apartmentId === removedAccount.apartmentId
				)
			) {
				const newInsurancePrepare = this.getInsurancePrepare.filter(
					item => item.apartmentId !== removedAccount.apartmentId
				);
				this.$store.commit("setInsurancePrepare", newInsurancePrepare);
			}
		},
		returnValid() {
			//проверка валидации сумм всего списка лс, только те, по которым возможна оплата
			let validate = this.payments.some(item => {
				return item.valid == false;
			});
			this.valid = !validate;
			return !validate;
		},
		buyInsurance(e) {
			if (e) {
				this.$store.commit(
					"setApartmentIds",
					this.payments
						.filter(item => item.canPay)
						.map(item => item.apartmentId)
				);
				// сохранение списка платежей перед тем как перейти на страницу страховок
				this.$store.commit("setPaymentsFromPaymentPage", this.payments);
				this.$router.push({
					name: "buy-insurance",
					params: {
						apartmentId: "group"
					}
				});
			} else {
				this.$store.commit("setInsurancePrepare", []);
				this.includeInsurance = false;
			}
		}
	},
	components: {
		SelectCardsModal,
		PaymentConfirmation
	},
	validations: {
		sumTotal: {
			minValue,
			maxValue
		},
		payments: {
			$each: {
				sum: {
					required,
					maxValue,
					minValueItem
				}
			}
		}
	}
};
</script>
<style scoped>
.insurance {
	text-align: center;
	padding: 0.5em 1em;
	white-space: nowrap;
	background-color: aquamarine;
	border-radius: 999px;
	color: var(--color-success);
	background: #1dc5711a;
}
</style>
