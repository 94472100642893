var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppGlobalLayout',{attrs:{"loading":_vm.getAccountListByAppartment.loading,"error":_vm.error},on:{"reload":_vm.listByAppartment}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('accountRename'),_c('remAccount'),_c('AccountMove'),_c('AccountDisconnect'),_c('PaperReceiptsModal',{attrs:{"invoiceDisabled":_vm.invoiceDisabled},on:{"refresh":_vm.apartmentList}}),_c('AppTabs',{staticClass:"mt-2",attrs:{"mobileRevers":"","data":_vm.tabs},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var active = ref.active;
return [(active == 0)?_c('div',[_c('div',{staticClass:"mt-3 d-flex align-center just-between mb-3--xs flex-wrap"},[_c('h3',{staticClass:"medium secondary-color"},[_vm._v(" Лицевые счета ")]),_c('div',{staticStyle:{"display":"flex","gap":"1em"}},[_c('AppBtn',{attrs:{"disabled":_vm.invoiceDisabled === null},nativeOn:{"click":function($event){return _vm.$modal.show('paper-receipts')}}},[(_vm.invoiceDisabled)?[_vm._v(" Получать бумажные квитанции ")]:[_vm._v(" Отказаться от бумажных квитанций ")]],2)],1)]),_c('AppTable',{staticClass:"table-account",attrs:{"header":_vm.headerTable,"data":_vm.getAccountListByAppartment.data,"messageNoData":"У Вас нет привязанных лицевых счетов или договоров. Нажмите на кнопку \"Добавить\", чтобы добавить ЛС или договор.","childRow":false},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"tbody",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{on:{"click":function($event){return _vm.routerLink(item, index)}}},[_c('span',[_vm._v(_vm._s(item.type))])]),_c('td',{on:{"click":function($event){return _vm.routerLink(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" ЛС / Договор: ")]),_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])]),_c('td',{on:{"click":function($event){return _vm.routerLink(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Поставщик: ")]),_c('span',[_vm._v(" "+_vm._s(item.organizationName))])]),_c('td',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.routerLink(item)}}},[_c('div',[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Баланс: ")]),_vm._v(" "+_vm._s(_vm.$digitNumber(item.debt))+" ₽ ")]),(item.status.message && item.status.message !== '' && item.status.message !== null)?_c('div',{staticClass:"debt-message"},[_vm._v(" "+_vm._s(item.status.message)+" ")]):_vm._e()]),_c('td',{staticClass:"table-account--border-ver",attrs:{"colspan":"1"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.routerLink(item)}}},[_c('div',{staticClass:"d-flex just-end",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.routerLink(item)}}},[_c('div',{staticClass:"balance-block__action mt-1"},[_c('AppBtn',{staticClass:"mr-2 mb-1",attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push({
													name: 'paymentPage',
													params: { accountId: item.id }
												})}}},[_vm._v(" Оплатить ")]),_c('AppBtn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.routerLink(item)}}},[_vm._v(" Подробнее ")])],1)])]),_c('td',[_c('div',{staticClass:"table-account__menu"},[_c('AppDropdown',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
												var showChange = ref.showChange;
return [_c('AppBtn',{attrs:{"icon":"","text":""},nativeOn:{"click":function($event){return showChange($event)}}},[_c('AppIcon',{staticStyle:{"color":"#777776"},attrs:{"name":"ellipsis-v","size":"1rem"}})],1)]}}],null,true)},[_c('div',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('AppList',{attrs:{"listData":_vm.listData},scopedSlots:_vm._u([{key:"list-item",fn:function(){return _vm._l((_vm.listData),function(menu,index){return _c('AppListItem',{key:index,attrs:{"name":menu.name},nativeOn:{"click":function($event){return _vm.clickMenuItem(menu.id, item)}}})})},proxy:true}],null,true)})],1)])],1)])]}}],null,true)})],1):_vm._e(),(active == 1)?_c('div',[_c('Insurance')],1):_vm._e()]}}])}),_c('snackbar',{ref:"snackbar"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }