<template>
	<div>
		<select-card-modal />
		<PaymentConfirmation />
		<div class="payment-all__footer">
			<AppCard class="payment-all__card">
				<template #body>
					<div class="payment-all__footer-row">
						<div>
							<div class="payment-all__card-img">
								<img
									v-if="getTheme !== 'dark-theme'"
									src="/img/pay-cards.png"
									alt=""
								/>
								<img v-else src="/img/dark-theme/visa-dark.png" alt="" />
							</div>
							<div class="payment-all__card-info" @click="showSelectCardsModal">
								<div class="payment-all__card-info-icon">
									<img src="img/payment-item-op.svg" alt="" />
								</div>
								<div class="payment-all__card-info-item">
									<div
										class="select"
										v-if="
											getSelectedCard.selected &&
												getProfileCards.find(it => it.id === getSelectedCard.id)
										"
									>
										{{
											"**" +
												getSelectedCard.pan
													.split("")
													.splice(-4, 4)
													.join("")
										}}
									</div>
									<div class="empty" v-else>Мои карты</div>
								</div>
								<AppIcon name="angle-down" size="20px" styleCss="color:white" />
							</div>
						</div>

						<div class="payment-all__payment-info-wrapper">
							<div
								class="payment-all__payment-info"
								style="justify-content: center; align-items: center;"
							>
								<div class="payment-all__total">
									Итого к оплате:
									<span class="payment-all__total-number">
										{{ getTotalPrice }} ₽
									</span>
								</div>
								<!-- Выводится комиссия или минимальная комиссия -->
							</div>

							<div class="payment-all__pay-button">
								<AppBtn :loading="loading" large @click.native="paymentSubmit">
									Оплатить
								</AppBtn>
							</div>
						</div>
					</div>
					<div class="payment-all__description">
						<a target="_blank" href="https://квартплата.онлайн/files/oferta.pdf"
							>Нажимая кнопку «Оплатить» я выражаю своё согласие на
							присоединение к <span>договору оферты</span> с условиями оказание
							услуг</a
						>
					</div>
				</template>
			</AppCard>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectCardModal from "../modals/select-cards-modal.vue";
import PaymentConfirmation from "../modals/payment-confirmation";
export default {
	components: { SelectCardModal, PaymentConfirmation },
	data() {
		return {
			loading: false
		};
	},
	computed: {
		...mapGetters([
			"getPayOrderData",
			"getTheme",
			"getSelectedCard",
			"getProfileCards",
			"getTotalPrice",
			"getInsurancePayments",
			"getInsurancePrepare"
		])
	},
	methods: {
		showSelectCardsModal() {
			this.$modal.show("select-cards");
		},
		showPaymentConfirmationModal() {
			this.$modal.show("insurance-payment-confirmation");
		},
		async loadCards() {
			const cardId = JSON.parse(localStorage.getItem("card-id"));
			if (!cardId) {
				this.$store.commit("setSelectedCard", "");
			} else this.$store.commit("setSelectedCard", cardId);
			try {
				await this.$store.dispatch("getCards");
				if (
					!this.getProfileCards.find(it => it.id === this.getSelectedCard.id)
				) {
					this.$store.commit("setSelectedNewCard");
					localStorage.setItem("card-id", JSON.stringify({ pan: "", id: "" }));
				}
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.$errorsMod(e);
				}
			}
		},
		async paymentSubmit() {
			this.loading = true;
			if (this.getInsurancePrepare.length > 0 && this.getTotalPrice) {
				this.$store.commit(
					"setInsurancePrepare",
					this.getInsurancePrepare.filter(item => item.canInsurance)
				);
			} else return;

			try {
				if (this.getSelectedCard.id !== "") {
					console.log("modal");
					this.showPaymentConfirmationModal();
				} else {
					await this.$store.dispatch("sendInsuranceCreate");

					const data = {
						payload: {
							data: [],
							insurancePayments: this.getInsurancePayments,
							cardId: this.getSelectedCard.id
						}
					};

					console.log("else");
					await this.$store.dispatch("payOrderSelected", data);

					if (
						this.getPayOrderData.data.successed &&
						!this.getSelectedCard.selected
					) {
						window.location.replace(this.getPayOrderData.data.url);
					} else if (!this.getPayOrderData.data.successed) {
						this.$router.push({
							path: `/bank/rejected?Success=false&message=${this.getPayOrderData.data.message}&orderId=${this.getPayOrderData.data.orderId}`
						});
					}
				}
			} catch (e) {
				this.$errorsMod(e.response);
			} finally {
				this.loading = false;
			}
		}
	},
	mounted() {
		this.loadCards();
	}
};
</script>
